import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import './item_card.scss';
import available from '../../../assets/item_card/available.svg';
import unavailable from '../../../assets/item_card/unavailable.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const DEFAULT_CLASSNAME = 'item_card';

const AVAILABLE_TEXT = 'В наличии';
const UNAVAILABLE_TEXT = 'Под заказ';

const AVAILABLE_SERVICE_TEXT = 'Доступна';
const UNAVAILABLE_SERVICE_TEXT = 'Недоступна';

export const ItemCard = React.memo(
  ({
    hideControls = false,
    product,
    clickLink,
    setSelectedCategory = () => {},
    serviceId,
    setFavoriteItems,
    image,
    isFavorite,
    link,
    isAvailable = true,
    title,
    price,
    isServiceItem = false,
    roundedBorders = true,
    compareMode = false,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const availableIcon = <img src={isAvailable ? available : unavailable} alt={'availability'} />;
    const availableText = isAvailable
      ? isServiceItem
        ? AVAILABLE_SERVICE_TEXT
        : AVAILABLE_TEXT
      : isServiceItem
      ? UNAVAILABLE_SERVICE_TEXT
      : UNAVAILABLE_TEXT;

    const availableContent = (
      <div className={`${DEFAULT_CLASSNAME}_availability_content`}>
        {availableIcon} {availableText}
      </div>
    );

    const favoriteIcon = <FontAwesomeIcon icon={faHeart} />;

    const { category } = useParams();

    const openProductHandler = (event, open = false) => {
      event.stopPropagation();

      setSelectedCategory(category);

      if (
        !isServiceItem &&
        (!open ||
          event.target.className !== 'item_card_content_buttons' ||
          event.target.closest('div').className !== 'item_card_content_buttons' ||
          event.target.className !== 'item_card_content_buttons_favorite' ||
          event.target.closest('div').className !== 'item_card_content_buttons_favorite') &&
        clickLink
      ) {
        if (clickLink === 'default') {
          navigate('/catalog/');
        }

        location.pathname.split('/').length < 5 && location.pathname.includes('catalog')
          ? navigate(link.split('/').slice(1).join('/'))
          : navigate(`/catalog/${clickLink}`);
        return;
      }

      if (
        !isServiceItem &&
        (!open ||
          event.target.className !== 'item_card_content_buttons' ||
          event.target.closest('div').className !== 'item_card_content_buttons' ||
          event.target.className !== 'item_card_content_buttons_favorite' ||
          event.target.closest('div').className !== 'item_card_content_buttons_favorite')
      ) {
        if (!isServiceItem && link) {
          if (link.includes('catalog')) {
            window.location = `${window.location.origin}/${link}`;
          } else {
            navigate(`${window.location.pathname}/${link}`);
          }
        }
      }

      if (
        isServiceItem &&
        (!open ||
          (event.target.className !== 'item_card_content_buttons_favorite' &&
            event.target.closest('div').className !== 'item_card_content_buttons_favorite'))
      ) {
        navigate(`/services/${serviceId}`);
      }
    };

    return (
      <div
        itemScope
        itemType={'https://schema.org/ItemList'}
        onClick={openProductHandler}
        className={`${DEFAULT_CLASSNAME} ${!isAvailable && 'unavailable'} ${
          hideControls && 'item-card-hide-controls'
        }`}
        style={{ borderRadius: roundedBorders && '12px', background: compareMode && '#FFF' }}>
        <div
          className={`${DEFAULT_CLASSNAME}_wrapper`}
          itemScope
          itemType={isServiceItem ? 'https://schema.org/Service' : 'https://schema.org/Product'}>
          {isServiceItem && <meta itemProp="serviceType" content={title} />}
          <div className={`${DEFAULT_CLASSNAME}_image_wrapper`}>
            <img
              itemProp="image"
              className={`${DEFAULT_CLASSNAME}_image`}
              src={image?.includes('http') ? image : `https://194.62.19.52:7000/${image}`}
              alt={title}
              title={`Купить ${title}`}
            />
          </div>
          <div className={`${DEFAULT_CLASSNAME}_content_status`}>
            <div className={`${DEFAULT_CLASSNAME}_availability`}>{availableContent}</div>
            {!isServiceItem && (
              <div
                style={{ display: 'none' }}
                itemScope
                itemProp="aggregateRating"
                itemType="https://schema.org/AggregateRating">
                <meta itemProp="reviewCount" content="100" />
                <meta itemProp="ratingValue" content="5" />
              </div>
            )}
          </div>
          <span className={`${DEFAULT_CLASSNAME}_content_title`} itemProp="name">{`${title}`}</span>
          {!isServiceItem && (
            <div
              style={{ display: 'none ' }}
              itemProp="offers"
              itemScope
              itemType="https://schema.org/Offer">
              <link itemProp="availability" href="https://schema.org/InStock" />
              <meta itemProp="priceCurrency" content="BYN" />
              <span itemProp="price">{price === 0 ? 'Уточните цену' : `${price}.00`}</span>{' '}
              <span>{price !== 0 && 'BYN'}</span>
            </div>
          )}
          {!isServiceItem && !hideControls && (
            <div className={`${DEFAULT_CLASSNAME}_content_buttons`}>
              <button
                aria-label={'add-to-favorite'}
                name={'add-to-favorite'}
                onClick={(event) => {
                  event.stopPropagation();
                  setFavoriteItems(product);
                }}
                style={{ color: isFavorite && 'red' }}
                className={`${DEFAULT_CLASSNAME}_content_buttons_favorite`}>
                {favoriteIcon}
              </button>
              <button
                onClick={(event) => openProductHandler(event, true)}
                className={`${DEFAULT_CLASSNAME}_content_buttons_add_to_card`}>
                <div className={`${DEFAULT_CLASSNAME}_content_buttons_add_to_card_price`}>
                  {price === 0 ? 'Уточните цену' : `${price}.00`}
                  {price !== 0 && ' BYN'}
                </div>
                <div className={`${DEFAULT_CLASSNAME}_content_buttons_add_to_card_btn`}>
                  Посмотреть
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  },
);
